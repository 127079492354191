import Navbar from '../Navbar/Navbar';
import Sidebar from '../Sidebar/Sidebar';
import { BiArrowBack } from 'react-icons/bi';


const Payment=()=>{
 const data=[
     {id:"0",date:"01 Jan 2023",ac:"3084399957657687",ref:"654537653764" ,trans:"-$65"},
     {ac:"3084399957657687",ref:"654537653764",trans:"-$20"},
     {date:"28 Dec 2022",ac:"3084399957657687",ref:"654537653764",trans:"-$45"},
     {ac:"3084399957657687",ref:"654537653764",trans:"-$50"},
     ];

    
    return(
        <div className='home'>
        <div>
            <Navbar/>
        </div>
        <div className='mainh'>
            <div><Sidebar/></div>
            <div className='tas' style={{width:"100%"}}> 
                <div style={{display:"flex"}} >
                <h2 style={{color:"#888888",marginTop:"2.8vh",marginLeft:"2vw" }}><a style={{cursor:"pointer"}} ><BiArrowBack/></a></h2>
                <h2 style={{marginLeft:"1vw"}}>Payment</h2>
                
               
                </div>
              
                <div className='container' style={{height: "77vh", width: "90%", overflow:"auto",
                  marginTop:"1vh",paddingLeft:"2vw",paddingRight:"2vw" }}>
                   {data.map((details , i)=>{
                       return(
                           <>
                     <div key={i}>
                         <div><h3 style={{marginBottom:"-0.8vh"}}>{details.date}</h3><hr 
                         style={{marginLeft:"9vw" , color:"1px solid #CDCDCD" ,
                          display: details.date === undefined ? "none" : "block"}}/></div>
                        
                         <div style={{display:"flex" , justifyContent:"space-between"}}>
                         <div style={{marginLeft:"4vw"}}>
                         <h3>Savings Account/payment<br/>{details.ac}</h3>
                         <h3 style={{color:"gray"}}>Chq/Ref No.:<br/>Savings Account-{details.ref}</h3>
                         </div>
                         <div>
                             <h3 style={{color:"green" ,marginRight:"10vw",paddingTop:"6vh"}}>{details.trans}</h3>
                         </div>
                         </div>
                     </div>
                 
                     </>
                       )
               
                   })}
                  
               
      
               
                </div>
            </div>
         
        
        </div>
        <div>
        
        </div>
    </div>



         )}

export default Payment;