import { useState, useEffect, useRef, useCallback } from "react";
import axios from "axios";
import { useForm } from "react-hook-form";
import { baseURL } from "../../api.js";
import FormLoader from "../FormLoader";
import { toast } from "react-toastify";
import Navbar from "../Navbar/Navbar";
import Sidebar from "../Sidebar/Sidebar";
import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";

const Query = () => {
  const id = JSON.parse(localStorage.getItem("id"));
  const inputref = useRef(null);

  const [open, setOpen] = React.useState(false);
  const [data, setdata] = useState([]);
  const [i, seti] = useState(0);
  // const [reply,setreply] = useState('')
  const reply = inputref?.current?.value;

  console.log("reply", reply);
  // const handleReply= (e)=>{
  //     setreply(e.target.value)

  // }
  useEffect(() => {
    (async () => {
      const res = await axios.get(`${baseURL}/admin/${id}/queries`);
      setdata(res.data);
    })();
    return () => {};
  }, []);
  const handleClickOpen = (i) => {
    setOpen(true);
    seti(i);
  };
  const userId = data[i]?.userId;

  const replyQuery = async (e) => {
    e.preventDefault();
    const res = await axios.post(
      `${baseURL}/admin/queryReply/${userId}`,
      { reply: inputref?.current?.value, question: data[i]?.question },
      {
        headers: { "Content-Type": "application/json" },
      }
    );

    handleClose();
  };

  function handleClose() {
    setOpen(false);
  }

  const FormDialog = () => {
    return (
      <div>
        <Dialog open={open} onClose={handleClose}>
          <DialogContent>
            <DialogContentText>{data[i]?.question}</DialogContentText>
            <form onSubmit={replyQuery}>
              <input
                ref={inputref}
                required
                style={{ width: "30vw", height: "4vh", marginTop: "2vh" }}
                type="text"
                value={reply}
                placeholder="Type your reply here"
              />
              <Button
                id="btn_submit"
                type="submit"
                style={{
                  height: "4.8vh",
                  width: "4vw",
                  background:
                    "linear-gradient( to right, #7A112F 0%,#C92564 100%)",
                  color: "white",
                  marginLeft: "1vw",
                }}
              >
                Send
              </Button>
            </form>
          </DialogContent>
        </Dialog>
      </div>
    );
  };

  return (
    <div className="home">
      <div>
        <Navbar />
      </div>
      <div className="mainh">
        <div>
          <Sidebar />
        </div>
        <div className="tas" style={{ width: "100%" }}>
          <div
            className="tasn"
            style={{ marginLeft: "1.6vw", width: "90%" }}
          ></div>
          <div style={{ display: "flex", marginLeft: "2vw", width: "95%" }}>
            <h2>Query</h2>
            <h2 className="quest">Total Queries:{data.length} </h2>
          </div>

          <div
            className="container"
            style={{
              height: "70vh",
              width: "90%",
              overflow: "auto",
              marginTop: "2vh",
              paddingLeft: "2vw",
              paddingRight: "0vw",
            }}
          >
            {data &&
              data.map((item, i) => {
                return (
                  <h4
                    onClick={() => handleClickOpen(i)}
                    style={{
                      cursor: "pointer",
                      color: "#000000",
                      fontSize: "13px",
                      fontWeight: "400",
                    }}
                  >
                    {i + 1} {item.question}
                  </h4>
                );
              })}
          </div>
        </div>
        <>
          <FormDialog />
        </>
      </div>
      <div></div>
    </div>
  );
};

export default Query;
