import React from "react";
import "./Sidebar.css";
import { useState } from "react";
import { Link } from "react-router-dom";
import { useLocation } from "react-router-dom";
import { useNavigate } from "react-router-dom";

import user from "./User.png";
import request from "./request.png";
import coin from "./Coin.png";
import payment from "./payment.png";
import categories from "./Category.png";
import ques from "./questions.png";
import blog from "./blog.png";
import query from "./query.png";
import { MdLogout } from "react-icons/md";

const Sidebar = () => {
  const navigate = useNavigate();
  const logout = () => {
    localStorage.removeItem("token");
    navigate("/");
  };

  const location = useLocation();

  return (
    <div className="sidebar ">
      <div
        styles={{ width: "18vw" }}
        className={location.pathname === "/home" ? "active" : "sbdiv"}
      >
        <div className="sbtuser">
          <img src={user} />
        </div>
        <div className="sbhuser">
          {" "}
          <Link to="/home">
            <h1>User Management</h1>
          </Link>
        </div>
      </div>
      <div
        styles={{ width: "18vw" }}
        className={location.pathname === "/requests" ? "active" : "sbdiv"}
      >
        <div>
          <img src={request} />
        </div>
        <div className="sbht">
          <Link to="/requests">
            <h1>Requests</h1>
          </Link>
        </div>
      </div>
      <div
        styles={{ width: "18vw" }}
        className={
          location.pathname === "/coins" ||
          location.pathname === "/offers" ||
          location.pathname === "/editcoins" ||
          location.pathname === "/deletecoins" ||
          location.pathname === "/plans"
            ? "active"
            : "sbdiv"
        }
      >
        <div className="sbtcoin">
          <img src={coin} />
        </div>
        <div className="sbhcoin">
          <Link to="/coins">
            <h1>Coins Management</h1>
          </Link>
        </div>
      </div>
      <div
        styles={{ width: "18vw" }}
        className={location.pathname === "/payments" ? "active" : "sbdiv"}
      >
        <div className="sbtpay">
          {" "}
          <img src={payment} />
        </div>
        <div className="sbhpay">
          {" "}
          <Link to="/payments">
            <h1>Payment</h1>
          </Link>
        </div>
      </div>
      <div
        styles={{ width: "18vw" }}
        className={
          location.pathname === "/blogs" || location.pathname === "/addblogs"
            ? "active"
            : "sbdiv"
        }
      >
        <div className="sbtblog">
          <img src={blog} />
        </div>
        <div className="sbhblog">
          <Link to="/blogs">
            <h1>Blog Management</h1>
          </Link>
        </div>
      </div>
      <div
        styles={{ width: "18vw" }}
        className={
          location.pathname === "/categories" ||
          location.pathname === "/addcat" ||
          location.pathname === "/form"
            ? "active"
            : "sbdiv"
        }
      >
        <div className="sbtcat">
          <img src={categories} />
        </div>
        <div className="sbhcat">
          <Link to="/categories">
            <h1>Categories</h1>
          </Link>
        </div>
      </div>
      <div
        styles={{ width: "18vw" }}
        className={
          location.pathname === "/questions" || location.pathname === "/addques"
            ? "active"
            : "sbdiv"
        }
      >
        <div className="sbtques">
          <img src={ques} />
        </div>
        <div className="sbhques">
          <Link to="/questions">
            <h1>Questions</h1>
          </Link>
        </div>
      </div>
      <div
        styles={{ width: "18vw" }}
        className={
          location.pathname === "/query" || location.pathname === "/queryreply"
            ? "active"
            : "sbdiv"
        }
      >
        <div className="sbtques">
          <img src={query} />
        </div>
        <div className="sbhques">
          <Link to="/query">
            <h1>Query Management</h1>
          </Link>
        </div>
      </div>
      <div styles={{ width: "18vw" }} className="flex mt-4 gap-2 pl-[2vw]">
        <div className="sbtques">
          <MdLogout style={{ height: "3.4vh", width: "2vw" }} />
        </div>
        <div className="sbhques">
          <Link to="/" onClick={logout}>
            <h1>Logout</h1>
          </Link>
        </div>
      </div>
    </div>
  );
};

export default Sidebar;
