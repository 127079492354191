import { useEffect, useState } from "react";
import axios from "axios";
import Navbar from "../Navbar/Navbar";
import Sidebar from "../Sidebar/Sidebar";
import { BiLeftArrowAlt, BiRightArrowAlt } from "react-icons/bi";
import * as React from "react";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import { baseURL } from "../../api.js";
import FormLoader from "../FormLoader";

const Request = () => {
  const [open, setOpen] = React.useState(false);
  const [idValue, setidValue] = useState("");
  const [i, seti] = useState(0);

  const handleClickOpen = (id, i) => {
    setOpen(true);
    setidValue(id);
    seti(i);
    console.log(idValue);
  };
  const handleClose = () => {
    setOpen(false);
  };
  const reject = async () => {
    console.log("click");
    try {
      await axios.post(
        `${baseURL}/admin/reject/${idValue}`,
        { action: "reject" },
        {
          headers: { "Content-Type": "application/json" },
        }
      );
      alert("Rejected !!");
      handleClose();
    } catch (error) {
      console.log(error);
    }
  };

  const [users, setusers] = useState([]);
  useEffect(() => {
    (async () => {
      const res = await axios.get(`${baseURL}/user/`);

      setusers(res.data);
    })();
    return () => {};
  }, []);

  const FormDialog = () => {
    return (
      <div>
        <Dialog
          sx={{ width: 1700 }}
          maxWidth={"md"}
          open={open}
          onClose={handleClose}
        >
          <DialogContent>
            <DialogContentText>
              <div style={{ display: "flex" }}>
                <div>
                  <img
                    src={users[i]?.profilePic?.url}
                    style={{
                      marginLeft: "0.5vw",
                      height: "6vh",
                      objectFit: "cover",
                      borderRadius: "6px",
                      width: "6vw",
                    }}
                  />
                </div>
                <div style={{ marginLeft: "1vw" }}>
                  <p style={{ marginTop: "0vh", marginBottom: "-2vh" }}>
                    {users[i]?.username}
                  </p>
                  <p>{users[i]?.email}</p>
                </div>
              </div>
              <div style={{ display: "flex", marginBottom: "2vh" }}>
                {users[i]?.images?.map((item, i) => {
                  return (
                    <div key={i}>
                      <img
                        style={{
                          height: "8vh",
                          height: "8vh",
                          borderRadius: "6px",
                          width: "6vw",
                          objectFit: "cover",
                          marginLeft: "0.5vw",
                        }}
                        src={item?.url}
                      />
                    </div>
                  );
                })}
              </div>
              <p>{users[i]?.description}</p>
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button
              onClick={reject}
              style={{
                height: "4vh",
                width: "6vw",
                border: "1px solid #7A112F ",
                background: "#FFFFFF",
                color: "#7A112F",
                marginLeft: "2vw",
              }}
            >
              Reject
            </Button>
            <Button
              style={{
                height: "4vh",
                width: "6vw",
                background:
                  "linear-gradient( to right, #7A112F 0%,#C92564 100%)",
                color: "white",
                marginLeft: "2vw",
              }}
              onClick={handleClose}
            >
              Approve
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    );
  };

  return (
    <div className="home">
      <div>
        <Navbar />
      </div>
      <div className="mainh">
        <div>
          <Sidebar />
        </div>
        <div className="tas" style={{ width: "100%" }}>
          <div
            style={{
              display: "flex",
              marginLeft: "2vw",
              height: "6vh",
              width: "95%",
            }}
          >
            <h2>Requests</h2>
            <h2 className="reqt" style={{}}>
              Total Users:{users.length}
            </h2>
          </div>
          <div
            style={{
              justifyContent: "flex-start",
              display: "flex",
              width: "95%",
              marginTop: "-2vh",
            }}
          >
            <h2>
              <a style={{ cursor: "pointer", paddingLeft: "73vw" }}>
                <BiLeftArrowAlt />
              </a>
            </h2>
            <h2>
              <a style={{ cursor: "pointer", paddingLeft: "2vw" }}>
                <BiRightArrowAlt />
              </a>
            </h2>
          </div>

          {users.length > 0 ? (
            <div
              className="container"
              style={{
                height: "76vh",
                marginTop: "-2vh",
                width: "95%",
                overflow: "scroll",
              }}
            >
              <table className="tab" style={{ width: "100%" }}>
                <tr className="tabtr">
                  <th style={{ textAlign: "start", paddingLeft: "2vw" }}>
                    User Name
                  </th>
                  <th style={{ textAlign: "start" }}>Email</th>
                  <th style={{ textAlign: "end" }}>Photo</th>
                  <th style={{ textAlign: "start", paddingLeft: "4vw" }}>
                    Description
                  </th>
                </tr>

                {users &&
                  users.map((item, i) => (
                    <tr
                      onClick={() => handleClickOpen(item._id, i)}
                      style={{ cursor: "pointer" }}
                      key={i}
                    >
                      <td
                        style={{
                          paddingLeft: "2vw",
                          "font-size": "15px",
                          textAlign: "start",
                        }}
                      >
                        {item.username}
                      </td>
                      <td
                        style={{
                          "font-size": "15px",
                          textAlign: "start",
                        }}
                      >
                        {item.email}
                      </td>
                      <td
                        style={{
                          "font-size": "15px",
                          textAlign: "end",
                          paddingTop: i === 0 ? "12px" : "",
                        }}
                      >
                        <img
                          style={{
                            height: "6vh",
                            objectFit: "cover",
                            width: "4vw",
                            borderRadius: "6px",
                          }}
                          src={item.profilePic?.url}
                        />
                      </td>
                      <td
                        style={{
                          "font-size": "15px",
                          textAlign: "start",
                          paddingLeft: "4vw",
                        }}
                      >
                        {item?.description.substring(0, 35)}
                      </td>
                      <td
                        style={{
                          overflow: "hidden",
                          "font-size": "15px",
                          textAlign: "start",
                        }}
                      >
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "flex-start",
                          }}
                        >
                          <button
                            style={{
                              height: "3vh",
                              width: "5vw",
                              color: "#7A112F",
                              background: "white",
                              cursor: "pointer",
                              border: "1px solid #7A112F",
                              borderRadius: "5px",
                            }}
                          >
                            Reject
                          </button>
                          <button
                            style={{
                              height: "3vh",
                              width: "5vw",
                              cursor: "pointer",
                              color: "white",
                              marginLeft: "1vw",
                              background:
                                "linear-gradient( to right, #7A112F 0%,#C92564 100%)",
                              borderRadius: "5px",
                            }}
                          >
                            Approve
                          </button>
                        </div>
                      </td>
                    </tr>
                  ))}
              </table>
            </div>
          ) : (
            <FormLoader open={true} />
          )}

          <>
            <FormDialog />
          </>
        </div>
      </div>
      <div></div>
    </div>
  );
};

export default Request;
