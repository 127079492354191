import Navbar from '../Navbar/Navbar';
import Sidebar from '../Sidebar/Sidebar';
import { BiArrowBack} from "react-icons/bi";
import { useNavigate } from 'react-router-dom';
import { useState } from 'react';
import axios from 'axios';

const Reportcoins=()=>{
    const navigate = useNavigate()
    const goBack=()=>{
           navigate("/coins")
    }
    const [plan , setplan] = useState("")
    const [coins,setcoins] = useState(0)
    const [number,setnumber] = useState(0)
    const [name,setname] = useState("") 

    const refundCoins= async ()=>{
            await axios.post("http://192.168.29.1:8088/api/admin/refundcoins" , {plan:plan ,coins:coins , number:number,username:name},
            {
               headers: { 'Content-Type': 'application/json' }
             })
             alert("Coins refunded")
    }

    return(
        <div className='home'>
            <div>
                <Navbar/>
            </div>
            <div className='mainh'>
                <div><Sidebar/></div>
                <div className='tas' style={{width:"100%"}}> 
                <div style={{display:"flex"}} >
                <h2 style={{color:"#888888",marginLeft:"2vw" }}><a onClick={goBack} style={{cursor:"pointer"}} ><BiArrowBack/></a></h2>
                <h2 style={{marginLeft:"1vw"}}>Report Coins</h2>
               
                </div>
                   
                  
                    <div className='container' style={{height: "76vh", width: "95%",
                          overflow:"auto",marginTop:"2vh" }}>
                      <div style={{display:"flex" , justifyContent:"space-evenly" , marginTop:"2vh"}}>
                          <div>
                              <h3>Number</h3>
                              <input type="text" value={number} placeholder='  Enter number' style={{width:"28vw",height:"3vh"}}/>
                          </div>
                          <div>
                              <h3>Username</h3>
                              <input type="text" value={name} placeholder='  Enter Email' style={{width:"28vw" , height:"3vh"}}/>      
                          </div>
      
                      </div>
                      <div style={{display:"flex" , justifyContent:"space-evenly" , marginTop:"8vh"}}>
                          <div>
                              <h3>Plan</h3>
                              <input type="text" value={plan} placeholder='  Enter plan' style={{width:"28vw",height:"3vh"}}/>
                          </div>
                          <div>
                              <h3>Coins</h3>
                              <input type="text" value={coins} placeholder='  Enter coins' style={{width:"28vw" , height:"3vh"}}/>      
                          </div>
      
                      </div>
                      
                      <div style={{display:"flex" , justifyContent:"flex-end"}}>
                          <button onClick={refundCoins} style={{ cursor:"pointer", height:"4vh",width:"10vw",marginTop:"34vh", color:"white", 
                        background:"linear-gradient( to right, #7A112F 0%,#C92564 100%)",borderRadius:"5px",
                        marginRight:"6vw"}}>
                            Send coins
                            </button>
                      </div>
                    </div>
                </div>
             
            
            </div>
            <div>
            
            </div>
        </div>

         )}

export default Reportcoins;