import { useState } from 'react';
import axios from 'axios';
import Navbar from '../Navbar/Navbar';
import Sidebar from '../Sidebar/Sidebar';
import { BiArrowBack} from "react-icons/bi";
import { useNavigate ,useParams} from 'react-router-dom';
import { baseURL } from "../../api.js";

const Editusers=()=>{
    const {id} = useParams()
    const navigate = useNavigate()
    const goBack=()=>{
           navigate("/home")
    }
    const [name,setname] = useState("")
    const [number,setnumber] = useState("")
    const [email,setemail] = useState("")
    const [password , setpassword] = useState(""
    )
    const handleNumber=(e)=>[
        setnumber(e.target.value)
    ]
    const handlePassword=(e)=>{
        setpassword(e.target.value)
    }
    const handlename=(e)=>{
        setname(e.target.value)
    }
    const handleemail=(e)=>{
        setemail(e.target.value)
    }
    const handleSubmit = async ()=>{
        const res = await axios.post(`http://192.168.29.1:8088/api/admin/edituser/${id}` , {number:number , password:password ,username: name ,email: email},
        {
           headers: { 'Content-Type': 'application/json' }
         })
         alert("User updated successfully")

    }
    return(
        <div className='home'>
            <div>
                <Navbar/>
            </div>
            <div className='mainh'>
                <div><Sidebar/></div>
                <div className='tas' style={{width:"100%"}}> 
                <div style={{display:"flex"}} >
                <h2 style={{color:"#888888",marginLeft:"2vw" }}><a onClick={goBack} style={{cursor:"pointer"}} ><BiArrowBack/></a></h2>
                <h2 style={{marginLeft:"1vw"}}>Edit</h2>
               
                </div>
                   
                  
                    <div className='container' style={{height: "76vh", width: "95%",
                          overflow:"auto",marginTop:"2vh" }}>
                      <div style={{display:"flex" , justifyContent:"space-evenly" , marginTop:"2vh"}}>
                          <div>
                              <h3>Name</h3>
                              <input type="text" value={name} onChange={handlename} placeholder='  Enter name' style={{width:"28vw",height:"3vh"}}/>
                          </div>
                          <div>
                              <h3>Mobile Number</h3>
                              <input type="text" value={number} onChange={handleNumber} placeholder='  Enter Mobile number' style={{width:"28vw" , height:"3vh"}}/>      
                          </div>
      
                      </div>
                      <div style={{display:"flex" , justifyContent:"space-evenly" , marginTop:"8vh"}}>
                          <div>
                              <h3>Email</h3>
                              <input type="text" value={email} onChange={handleemail} placeholder='  Enter Email' style={{width:"28vw",height:"3vh"}}/>
                          </div>
                          <div>
                              <h3>Plan</h3>
                              <input type="text" value={password} onChange={handlePassword} placeholder='  Enter Plan' style={{width:"28vw" , height:"3vh"}}/>      
                          </div>
      
                      </div>
                      
                      <div style={{display:"flex" , justifyContent:"flex-end"}}>
                          <button onClick={handleSubmit} style={{ cursor:"pointer", height:"4vh",width:"8vw",marginTop:"34vh", color:"white", 
                        background:"linear-gradient( to right, #7A112F 0%,#C92564 100%)",borderRadius:"5px",
                        marginRight:"6vw"}}>
                            upload
                            </button>
                      </div>
                    </div>
                </div>
             
            
            </div>
            <div>
            
            </div>
        </div>

         )}

export default Editusers;