import Navbar from '../Navbar/Navbar';
import Sidebar from '../Sidebar/Sidebar';
import { BiArrowBack} from "react-icons/bi";
import { useNavigate } from 'react-router-dom';
import { useState } from 'react';

const Form=()=>{
    const [selectedBoxes, setSelectedBoxes] = useState([]);
    const navigate = useNavigate()
    const goBack=()=>{
           navigate("/categories")
    }
    function handleBoxClick(boxId) {
        if (selectedBoxes.includes(boxId)) {
            // If the box is already selected, remove it from the list
            setSelectedBoxes(selectedBoxes.filter((id) => id !== boxId));
          } else {
            // If the box is not selected, add it to the list
            setSelectedBoxes([...selectedBoxes, boxId]);
          }
       
      }
    const boxes = [
        { id: 0,  type:"Name" },
        { id: 1, type:"Phone Number"},
        { id: 2,  type:"Address" },
        { id: 3,  type:"D.O.B" },
        { id: 4,  type:"Gender" },
        { id: 5,  type:"City" },
        { id: 6,  type:"State" },
        { id: 7,  type:"Country" },
        { id: 8,  type:"Zodiac sign" },
        { id: 9,  type:"Income" },
        { id: 10,  type:"Occupation" },
        { id: 11,  type:"Marital Status" },
        { id: 12,  type:"Children" },
        { id: 13,  type:"Sexual Orientation" },
        { id: 14,  type:"Height" },
        { id: 15,  type:"Figure" },
        { id: 16,  type:"Ethnicity" },
        { id: 17,  type:"Hair Color" },
        { id: 18,  type:"Password" },
        { id: 19,  type:"Eye color" },
        { id: 20,  type:"Smoke" },


      ];
    return(
        <div className='home'>
            <div>
                <Navbar/>
            </div>
            <div className='mainh'>
                <div><Sidebar/></div>
                <div className='tas' style={{width:"100%"}}> 
                <div style={{display:"flex"}} >
                <h2 style={{color:"#888888",marginTop:"1.6vw",marginLeft:"2vw" }}><a onClick={goBack} style={{cursor:"pointer"}} ><BiArrowBack/></a></h2>
                <h2 style={{marginLeft:"1vw"}}>Create Form</h2>
               
                </div>
                   
                  
                    <div className='container' style={{height: "76vh", width: "90%",
                          overflow:"auto",marginTop:"2vh",paddingLeft:"2vw",paddingRight:"2vw" }}>
                      
                      <h2 >Enter Category</h2>
               <h3>Heading</h3>
               <input  style={{width:"30vw",height:"4vh", border:"1px solid #DADADA",borderRadius: "5px"}} type="text" placeholder="  Select Category that you want to show"/>
               <h3>Select Options</h3>
               <div style={{height:"32vh" , width:"56vw", border:"1px solid #DADADA",borderRadius: "5px" }}>
               <div style={{display:"flex",width:"38vw",flexWrap:"wrap"  ,margin:" 4vh 9vw"}}>
               {boxes.map((box , i)=>(
                         <>
                        <div key={i}
                         
                         onClick={() => handleBoxClick(box.id)}
                       
                            style={{ border: selectedBoxes.includes(box.id) ? "1px solid #7A112F":"1px solid #DADADA",
                            color: selectedBoxes.includes(box.id) ? "#7A112F":"black",
                            cursor:"pointer",maxWidth:"10vw",fontSize:"12px",height:"2vh",margin:"1.5vh 0.5vw",
                            padding:"5px" ,borderRadius:"30px" }}>{box.type}</div>
                    
                         </>
      ))}
               
                        </div>
      
        
                      
                    
                      </div>
                      <div style={{display:"flex" , justifyContent:"flex-end"}}>
                          <button style={{height:"4vh",width:"8vw",marginTop:"8vh", color:"white", 
                        background:"linear-gradient( to right, #7A112F 0%,#C92564 100%)",borderRadius:"5px",
                        marginRight:"2vw"}}>
                            Add Category
                            </button>
                      </div>
                    </div>
                </div>
             
            
            </div>
            <div>
            
            </div>
        </div>

         )}

export default Form;