import { useEffect, useState } from "react";
import { Form, useNavigate } from "react-router-dom";
import { Link } from "react-router-dom";
import axios from "axios";
import { baseURL } from "../../api";
import "./Home.css";
import FormLoader from "../FormLoader";
import Navbar from "../Navbar/Navbar";
import Sidebar from "../Sidebar/Sidebar";
import {
  BiDotsVerticalRounded,
  BiLeftArrowAlt,
  BiRightArrowAlt,
} from "react-icons/bi";
import { MdModeEditOutline, MdDelete } from "react-icons/md";

const Home = () => {
  const navigate = useNavigate();
  const [index, setIndex] = useState(null);
  const block = async (id) => {
    await axios.post(
      `${baseURL}/admin/blockunblockuser/${id}`,
      { action: "block" },
      {
        headers: { "Content-Type": "application/json" },
      }
    );
    alert("user blocked");
  };
  function handleMenuIconClick(id) {
    if (index == id) {
      setIndex(null);
    } else {
      setIndex(id);
    }
    // setIsOpen(!isOpen);
  }

  const [users, setusers] = useState([]);
  useEffect(() => {
    (async () => {
      const res = await axios.get(`${baseURL}/user/`);
      
      setusers(res.data);
    })();
    return () => {};
  }, []);

  return (
    <div className="home">
      <div>
        <Navbar />
      </div>
      <div className="mainh">
        <div className="upd">
          <Sidebar />
        </div>
        <div className="tas" style={{ width: "100%" }}>
          <div
            style={{
              display: "flex",
              marginLeft: "2vw",
              height: "6vh",
              width: "94%",
            }}
          >
            <h2>All Users</h2>
            <h2 className="homet">Total Users:{users.length}</h2>
          </div>
          <div
            style={{
              justifyContent: "flex-start",
              display: "flex",
              width: "94%",
              marginTop: "-2vh",
            }}
          >
            <h2>
              <a style={{ cursor: "pointer", paddingLeft: "73vw" }}>
                <BiLeftArrowAlt />
              </a>
            </h2>
            <h2>
              <a style={{ cursor: "pointer", paddingLeft: "2vw" }}>
                <BiRightArrowAlt />
              </a>
            </h2>
          </div>
          {users.length > 0 ? (
            <div
              className="container"
              style={{
                height: "76.5vh",
                width: "95%",
                marginTop: "-2vh",
                overflow: "auto",
              }}
            >
              <table className="tab" style={{ width: "100%" }}>
                <tr className="tabtr">
                  <th
                    style={{
                      textAlign: "start",
                      marginTop: "1vh",
                      paddingLeft: "2vw",
                    }}
                  >
                    Photo
                  </th>

                  <th style={{ textAlign: "start" }}> User Name</th>
                  <th style={{ textAlign: "start" }}> Mobile </th>
                  <th style={{ textAlign: "start" }}> Email </th>
                  <th style={{ textAlign: "start" }}> Coins </th>
                  <th style={{ textAlign: "end" }}> Status </th>
                  <th style={{ textAlign: "center" }}>Operation</th>
                </tr>

                {users &&
                  users.map((item, i) => (
                    <tr key={i} style={{}}>
                      <td
                        style={{
                          paddingLeft: "2vw",
                          paddingTop: i === 0 ? "8px" : "",
                        }}
                      >
                        <img
                          style={{
                            height: "6vh",
                            objectFit: "cover",
                            width: "4vw",
                            borderRadius: "6px",
                          }}
                          src={item.profilePic?.url}
                        />
                      </td>
                      <td
                        style={{
                          "font-size": "15px",
                          textAlign: "start",
                        }}
                      >
                        {item.username}
                      </td>
                      <td
                        style={{
                          "font-size": "15px",
                          textAlign: "start",
                        }}
                      >
                        {item.number}
                      </td>
                      <td
                        style={{
                          "font-size": "15px",
                          textAlign: "start",
                        }}
                      >
                        {item.email}
                      </td>
                      <td
                        style={{
                          "font-size": "15px",
                          textAlign: "start",
                        }}
                      >
                        {item.coins}
                      </td>
                      <td
                        style={{
                          color: item.isActive === true ? "green" : "red",
                          "font-size": "15px",
                          textAlign: "end",
                          color: item.isActive === true ? "green" : "red",
                        }}
                      >
                        {item.isActive === true ? "Active" : "inActive"}
                      </td>
                      <td
                        style={{
                          "font-size": "15px",
                          textAlign: "center",
                        }}
                      >
                        <a
                          onClick={() => handleMenuIconClick(item._id)}
                          style={{ cursor: "pointer" }}
                        >
                          <BiDotsVerticalRounded />
                        </a>
                      </td>
                      <div
                        style={{
                          boxShadow: "0px 4px 20px rgba(0, 0, 0, 0.15)",
                          background: "#FFFFFF",
                          borderRadius: "10px",
                          display: index === item._id ? "block" : "none",
                          position: "absolute",
                          right: "1.5vw",
                        }}
                      >
                        <>
                          {/* <Link
                style={{ textDecoration: "none" }}
                to={`/editusers/${item._id}`}
              >
                <div
                  style={{
                    cursor: "pointer",
                    display: "flex",
                    padding: "4px",
                    borderBottom: "1px solid black",
                  }}
                >
                  <MdModeEditOutline
                    style={{ paddingRight: "2vw" }}
                  />
                  <h5 style={{ margin: "0" }}>Edit</h5>
                </div>
              </Link> */}

                          <div
                            onClick={() => block(item._id)}
                            style={{
                              cursor: "pointer",
                              display: "flex",
                              padding: "4px",
                              borderBottom: "1px solid black",
                            }}
                          >
                            <MdDelete style={{ paddingRight: "2vw" }} />
                            <h5 style={{ margin: "0" }}>Block</h5>{" "}
                          </div>
                        </>
                      </div>
                    </tr>
                  ))}
              </table>
            </div>
          ) : (
            <FormLoader open={true} />
          )}
        </div>
      </div>
      <div></div>
    </div>
  );
};

export default Home;
