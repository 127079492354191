import Navbar from "../Navbar/Navbar";
import Sidebar from "../Sidebar/Sidebar";
import { useNavigate } from "react-router-dom";
import { MdExpandMore } from "react-icons/md";
import { useState, useEffect } from "react";
import axios from "axios";
import { baseURL } from "../../api.js";

const Categories = () => {
  const navigate = useNavigate();
  const [index, setindex] = useState(null);
  const goCat = () => {
    navigate("/addcat");
  };
  const goForm = () => {
    navigate("/form");
  };
  function handleClick(j) {
    if (index === j) {
      setindex(null);
    } else {
      setindex(j);
    }
    // setIsOpen(!isOpen);
  }
  const [data, setdata] = useState([]);
  useEffect(() => {
    (async () => {
      const { data } = await axios.get(`${baseURL}/category/`);

      setdata(data);
    })();
    return () => {};
  }, []);
  return (
    <div className="home">
      <div>
        <Navbar />
      </div>
      <div className="mainh">
        <div>
          <Sidebar />
        </div>
        <div className="tas" style={{ width: "100%" }}>
          <div className="catb" style={{}}>
            <button
              onClick={goCat}
              style={{
                cursor: "pointer",
                height: "4vh",
                marginLeft: "54vw",
                width: "12vw",
                marginTop: "1vh",
                color: "white",
                background:
                  "linear-gradient( to right, #7A112F 0%,#C92564 100%)",
                borderRadius: "5px",
              }}
            >
              Add New Category
            </button>
            <button
              onClick={goForm}
              style={{
                cursor: "pointer",
                height: "4vh",
                width: "8vw",
                marginTop: "1vh",
                marginLeft: "2vw",
                color: "white",
                background:
                  "linear-gradient( to right, #7A112F 0%,#C92564 100%)",
                borderRadius: "5px",
              }}
            >
              Create Form
            </button>
          </div>
          <div style={{ display: "flex", marginLeft: "2vw", width: "95%" }}>
            <h2>Categories</h2>
            <h2 className="catt">Total Categories:6</h2>
          </div>

          <div
            className="container"
            style={{
              height: "70vh",
              width: "95%",
              overflow: "auto",
              marginTop: "2vh",
            }}
          >
            {data.map((item, j) => {
              return (
                <>
                  <div key={j}>
                    <div
                      style={{
                        display: "flex",
                        margin: " -1vh 4vw",
                        justifyContent: "space-between",
                      }}
                    >
                      <h3 style={{ color: "#7A112F" }}>{data[j].category}</h3>
                      <a
                        onClick={() => handleClick(j)}
                        style={{
                          color: "#7A112F",
                          cursor: "pointer",
                          fontSize: "30px",
                          paddingTop: "2vh",
                        }}
                      >
                        <MdExpandMore />
                      </a>
                    </div>
                    <div
                      style={{
                        height: "42vh",
                        borderRadius: "10px",
                        background: "#FFF8FA",
                        width: "48vw",
                        boxShadow: "0px 4px 40px rgba(0, 0, 0, 0.1)",
                        display: index === j ? "block" : "none",
                        marginLeft: "13vw",
                      }}
                    >
                      <div
                        style={{
                          display: "flex",
                          paddingTop: "3vh",
                          justifyContent: "center",
                          margin: "  0vh 5.6vw",
                          flexWrap: "wrap",
                        }}
                      >
                        {data[j].options.map((item, i) => {
                          return (
                            <div
                              key={i}
                              style={{
                                fontSize: "12px",
                                padding: "5px",
                                maxWidth: "10vw",
                                margin: "1vh 0.5vw",
                                border: "1px solid #ADADAD",
                                textAlign: "center",
                                borderRadius: "30px",
                              }}
                            >
                              {item.value}
                            </div>
                          );
                        })}
                      </div>
                      <button
                        style={{
                          width: "10%",
                          cursor: "pointer",
                          marginLeft: "21vw",
                          marginTop: "2vh",
                          height: "10%",
                          background:
                            "linear-gradient( to right, #7A112F 0%,#C92564 100%)",
                          color: "white",
                          borderRadius: "5px",
                        }}
                      >
                        Add
                      </button>
                    </div>
                  </div>
                </>
              );
            })}
          </div>
        </div>
      </div>
      <div></div>
    </div>
  );
};

export default Categories;
