import Navbar from "../Navbar/Navbar";
import Sidebar from "../Sidebar/Sidebar";
import { useState } from "react";
import axios from "axios";
import FormLoader from "../FormLoader";
import { toast } from "react-toastify";
import ToastViewer from "../ToastViewer";
import { useForm } from "react-hook-form";
import { baseURL } from "../../api.js";
import { BiArrowBack } from "react-icons/bi";
import { useNavigate } from "react-router-dom";
const Offers = () => {
  const {
    handleSubmit,
    formState: { isSubmitting },
  } = useForm();
  const navigate = useNavigate();
  const goBack = () => {
    navigate("/coins");
  };
  const [amount, setamount] = useState("");
  const [coins, setcoins] = useState("");
  const [date, setdate] = useState(new Date());
  const [description, setdescription] = useState("");
  const handleamount = (e) => {
    setamount(e.target.value);
  };
  const handlecoins = (e) => {
    setcoins(e.target.value);
  };
  const handledate = (e) => {
    setdate(e.target.value);
  };
  const handledescription = (e) => {
    setdescription(e.target.value);
  };
  const createOffer = async () => {
    const { data } = await axios.post(
      `${baseURL}/coins/offer`,
      { plan: amount, coins: coins, date: date, description: description },
      {
        headers: { "Content-Type": "application/json" },
      }
    );
    toast.success("Offer Added");
  };
  return (
    <div className="home">
      <div>
        <Navbar />
      </div>
      <div className="mainh">
        <div>
          <Sidebar />
        </div>
        <div className="tas" style={{ width: "81%" }}>
          <div style={{ display: "flex", marginLeft: "2vw", width: "80%" }}>
            <h2 style={{ color: "#888888" }}>
              <a style={{ cursor: "pointer" }} onClick={goBack}>
                <BiArrowBack />
              </a>
            </h2>
            <h2 style={{ marginLeft: "2.6vw" }}>Create Offer</h2>
          </div>

          <form
            onSubmit={handleSubmit(createOffer)}
            className="container"
            style={{
              height: "76vh",
              width: "92%",
              overflow: "auto",
              marginTop: "2vh",
            }}
          >
            <div
              style={{
                display: "flex",
                justifyContent: "space-evenly",
                paddingLeft: "10vw",
                marginRight: "10vw",
                marginTop: "3vh",
              }}
            >
              <div>
                <h3>Enter Amount</h3>
                <input
                  type="text"
                  value={amount}
                  onChange={handleamount}
                  placeholder="   Enter amount"
                  style={{ width: "26vw", height: "3vh" }}
                />
              </div>
              <div style={{ paddingLeft: "7vw" }}>
                <h3>Enter Coins</h3>
                <input
                  type="text"
                  value={coins}
                  onChange={handlecoins}
                  placeholder="   Enter coins"
                  style={{ width: "26vw", height: "3vh" }}
                />
              </div>
            </div>
            <div style={{ marginLeft: "8vw", marginTop: "8vh" }}>
              <h3>Date</h3>
              <input
                type="date"
                value={date.toString().split("T")[0]}
                onChange={handledate}
                style={{ width: "20vw", height: "4vh" }}
              />
            </div>
            <div style={{ marginLeft: "8vw", marginTop: "8vh" }}>
              <h3>Description</h3>
              <textarea
                type="text"
                value={description}
                onChange={handledescription}
                placeholder="Enter Description"
                style={{ width: "59.6vw", height: "16vh" }}
              />
            </div>
            <h5 style={{ marginLeft: "8vw", marginTop: "0vw" }}>
              <a style={{ color: "" }}>*</a>
              <a style={{ color: "##7B8798" }}>Maximum word limit is 200-500</a>
            </h5>
            <div style={{ display: "flex", justifyContent: "flex-end" }}>
              <button
                disabled={isSubmitting}
                style={{
                  height: "4vh",
                  cursor: "pointer",
                  width: "6vw",
                  marginTop: "2vh",
                  color: "white",
                  background:
                    "linear-gradient( to right, #7A112F 0%,#C92564 100%)",
                  borderRadius: "7px",
                  marginRight: "8vw",
                  border: "none",
                  outline: "none",
                }}
              >
                Upload
              </button>
              {isSubmitting && <FormLoader open={true} />}
              <ToastViewer />
            </div>
          </form>
        </div>
      </div>
      <div></div>
    </div>
  );
};

export default Offers;
