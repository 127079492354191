import Navbar from "../Navbar/Navbar";
import Sidebar from "../Sidebar/Sidebar";
import { BiArrowBack } from "react-icons/bi";
import { useNavigate } from "react-router-dom";
import { useState } from "react";
import axios from "axios";
import { useForm } from "react-hook-form";
import { baseURL } from "../../api.js";
import FormLoader from "../FormLoader";
import {toast} from 'react-toastify';
import ToastViewer from "../ToastViewer";
const Addblogs = () => {
  const {
    handleSubmit,
    formState: { isSubmitting },
  } = useForm();
  const navigate = useNavigate();
  const goBack = () => {
    navigate("/blogs");
  };
  const [heading, setheading] = useState("");
  const [writers_name, setwriters_name] = useState("");
  const [date, setdate] = useState(new Date());
  const [description, setdescription] = useState("");
  const [image, setimage] = useState("");

  const handleheading = (e) => {
    setheading(e.target.value);
  };
  const handlewriters_name = (e) => {
    setwriters_name(e.target.value);
  };
  const handledate = (e) => {
    setdate(e.target.value);
  };
  const handledescription = (e) => {
    setdescription(e.target.value);
  };
  const handleimage = (e) => {
    console.log(e.target.files[0]);
    setimage(e.target.files[0]);
  };
  const submitForm = async (e) => {
    try {
      const formData = new FormData();
      formData.append("image", image);
      formData.append("heading", heading);
      formData.append("writers_name", writers_name);
      formData.append("description", description);
      formData.append("date", date);

      const { data } = await axios.post(`${baseURL}/blogs/`, formData, {
        headers: {
          "content-type": "multipart/form-data",
        },
      });
      toast.success('Blog Added')
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <div className="home ">
      <div>
        <Navbar />
      </div>
      <div className="mainh">
        <div>
          <Sidebar />
        </div>
        <div className="tas" style={{ width: "100%" }}>
          <div style={{ display: "flex" }}>
            <h2 style={{ color: "#888888", marginLeft: "2vw" }}>
              <a onClick={goBack} style={{ cursor: "pointer" }}>
                <BiArrowBack />
              </a>
            </h2>
            <h2 style={{ marginLeft: "1vw" }}>Add More Blogs</h2>
          </div>

          <div
            className="container"
            style={{
              maxHeight: "76vh",
              width: "95%",
              overflow: "scroll",
              marginTop: "2vh",
            }}
          >
            <form
              enctype="multipart/form-data"
              onSubmit={handleSubmit(submitForm)}
            >
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-evenly",
                  marginTop: "2vh",
                  padding: "0vh",
                }}
              >
                <div style={{ paddingRight: "6vw" }}>
                  <h3>Heading</h3>
                  <input
                    type="text"
                    value={heading}
                    onChange={handleheading}
                    placeholder="  Enter Heading"
                    style={{ width: "20vw", height: "3vh" }}
                  />
                </div>
                <div style={{ paddingRight: "6vw" }}>
                  <h3>Writer's Name</h3>
                  <input
                    type="text"
                    value={writers_name}
                    onChange={handlewriters_name}
                    placeholder="  Enter Writer's name"
                    style={{ width: "20vw", height: "3vh" }}
                  />
                </div>
              </div>
              <div style={{ marginLeft: "8vw", marginTop: "4vh" }}>
                <h3>Date</h3>
                <input
                  type="date"
                  value={date.toString().split("T")[0]}
                  onChange={handledate}
                  style={{ width: "20vw", height: "4vh" }}
                />
              </div>
              <div style={{ marginLeft: "8vw", marginTop: "6vh" }}>
                <h3 style={{}}>Description</h3>
                <textarea
                  type="text"
                  value={description}
                  onChange={handledescription}
                  placeholder=" Enter Description"
                  style={{ width: "54.6vw", height: "16vh" }}
                />
              </div>
              <h5 style={{ marginLeft: "8vw", marginTop: "0vw" }}>
                <a style={{ color: "red" }}>*</a>Maximum word limit is 200-500
              </h5>
              <div style={{ marginLeft: "8vw", marginTop: "4vh" }}>
                <h3>Upload pictures</h3>
                <div style={{ display: "flex" }}>
                  <input
                    type="file"
                    accept="image"
                    onChange={handleimage}
                    style={{ marginRight: "2vw" }}
                  />
                </div>
              </div>
              <div style={{ display: "flex", justifyContent: "flex-end" }}>
                <button
                  className="border-none outline-none cursor-pointer"
                  disabled={isSubmitting}
                  type="submit"
                  style={{
                    height: "4vh",
                    width: "8vw",
                    marginTop: "3.5vh",
                    color: "white",
                    background:
                      "linear-gradient( to right, #7A112F 0%,#C92564 100%)",
                    borderRadius: "5px",
                    marginRight: "14vw",
                    marginBottom: "4vw",
                  }}
                >
                  Upload
                </button>
              </div>
              {isSubmitting && <FormLoader open={true} />}
              <ToastViewer/>
            </form>
          </div>
        </div>
      </div>
      <div></div>
    </div>
  );
};

export default Addblogs;
