import Navbar from "../Navbar/Navbar";
import Sidebar from "../Sidebar/Sidebar";
import { useState } from "react";
import axios from "axios";
import { baseURL } from "../../api.js";
import CreatableSelect from "react-select/creatable";
import { BiArrowBack } from "react-icons/bi";
import { Form, useNavigate } from "react-router-dom";
import FormLoader from "../FormLoader";
import { toast } from "react-toastify";
import ToastViewer from "../ToastViewer";
import { useForm } from "react-hook-form";

const Addcategories = () => {
  const {
    handleSubmit,
    formState: { isSubmitting },
  } = useForm();
  const navigate = useNavigate();
  const [options, setoptions] = useState({
    value: "",
    label: "",
  });
  const [category, setcategory] = useState("");
  const goBack = () => {
    navigate("/categories");
  };
  const handlecategory = (e) => {
    setcategory(e.target.value);
  };
  const submitForm = async (e) => {
    try {
      const res = await axios.post(
        `${baseURL}/category/`,
        { category: category, options },
        {
          headers: { "Content-Type": "application/json" },
        }
      );
      toast.success('Category Added');
    } catch (error) {
      console.log(error);
    }
  };
  return (
    <div className="home">
      <div>
        <Navbar />
      </div>
      <div className="mainh">
        <div>
          <Sidebar />
        </div>
        <div className="tas" style={{ width: "100%" }}>
          <div style={{ display: "flex" }}>
            <h2 style={{ color: "#888888", marginLeft: "2vw" }}>
              <a onClick={goBack} style={{ cursor: "pointer" }}>
                <BiArrowBack />
              </a>
            </h2>
            <h2 style={{ marginLeft: "1vw" }}>Add Category</h2>
          </div>

          <div
            className="container"
            style={{
              height: "76vh",
              width: "95%",
              overflow: "auto",
              marginTop: "2vh",
            }}
          >
            <form onSubmit={handleSubmit(submitForm)}>
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-evenly",
                  marginTop: "8vh",
                }}
              >
                <div>
                  <h3>Category Name</h3>
                  <input
                    required
                    type="text"
                    value={category}
                    onChange={handlecategory}
                    placeholder="  Enter Category name"
                    style={{ width: "28vw", height: "3vh" }}
                  />
                </div>
                <div>
                  <h3>Options</h3>
                  {/* <input type="text" placeholder='  Enter options related to category' />   */}

                  <CreatableSelect
                    isMulti
                    style={{ width: "28vw", height: "3vh" }}
                    value={options}
                    onChange={setoptions}
                  />
                </div>
              </div>

              <div style={{ display: "flex", justifyContent: "flex-end" }}>
                <button
                  type="submit"
                  style={{
                    cursor: "pointer",
                    height: "4vh",
                    width: "8vw",
                    marginTop: "40vh",
                    color: "white",
                    background:
                      "linear-gradient( to right, #7A112F 0%,#C92564 100%)",
                    borderRadius: "5px",
                    marginRight: "6vw",
                  }}
                >
                  Add Category
                </button>
                {
                  isSubmitting && <FormLoader open={true}/>
                }
               <ToastViewer/>
              </div>
            </form>
          </div>
        </div>
      </div>
      <div></div>
    </div>
  );
};

export default Addcategories;
