import React from 'react';
import { useEffect } from 'react';
import { BrowserRouter as Router } from "react-router-dom";
import { isJwtExpired } from "jwt-check-expiration";
import Login from './components/Login/Login';
import RoutesList from './RoutesList'
import "react-toastify/dist/ReactToastify.css";
function App() {
 
  const token = JSON.parse(localStorage.getItem("token"));
  const tokencheck = () => {
      if (!token) {
        return <Login/>
      }
  };

  useEffect(() => {
      tokencheck();
  });

  return (
    <Router>
       <RoutesList/>
    </Router>
  );  
}

export default App;
