import { useRef,useState,useEffect ,React } from 'react';
import axios from 'axios';
import Navbar from '../Navbar/Navbar';
import Sidebar from '../Sidebar/Sidebar';
import { BiArrowBack} from "react-icons/bi";
import { useNavigate } from 'react-router-dom';
import cam from "./cam.png"
const Editprofile=()=>{
    const navigate = useNavigate()
    const goBack=()=>{
        navigate("/home")
    }
    const id = JSON.parse(localStorage.getItem("id") )
  
    const [dataa,setdataa] = useState({})
    const [image,setimage] = useState('')
    const [username , setusername] = useState('')
    const [number,setnumber] = useState('')
    const [email,setemail] = useState('')
    const [password , setpassword] = useState('')
   
    const fetchData= async()=>{
       const {data} = await axios.get(`http://192.168.29.1:8088/api/admin/${id}`)
       setdataa(data)
       const initialimage = data?.profilePic.url || '' ;
       const initialusername = data?.username || '';
       const initialnumber = data?.number || ''    ;
       const initialemail = data?.email || ''      ;
       const initialpassword = data?.password || '';
       setusername(initialusername);
       setnumber(initialnumber);
       setemail(initialemail);
       setimage(initialimage)  
    }
    console.log(dataa)
    useEffect(()=>{
       fetchData() 
    },[])

    const handleusername=(e)=>{
        setusername(e.target.value)   
    }
    const handlenumber=(e)=>{
         setnumber(e.target.value)
    }
    const handleemail=(e)=>{
          setemail(e.target.value)
    }
    const handlepassword=(e)=>{
          setpassword(e.target.value)
    }
    const handleimage=(e)=>{
        setimage(e.target.files[0])
    }
    const handleSubmit = async (e)=>{
        try {
           console.log("click")
           e.preventDefault()
           const formData = new FormData()
           formData.append('username' , username)
           formData.append('number' ,number)
           formData.append('password' , password)
           formData.append('email' , email)
          await axios.post(`http://192.168.1.16:8088/api/audition/editprofile/${id}` , formData , { headers: {
        'content-type': 'multipart/form-data',
      }},)
      alert("form submitted") 
            
        } catch (error) {
            console.log(error)
        }
           
     }
 
    return(
        <div className='home'>
            <div>
                <Navbar/>
            </div>
            <div className='mainh'>
                <div><Sidebar/></div>
                <div className='tas' style={{width:"81%"}}> 
                    <div style={{display:"flex",marginLeft:"2vw",width:"80%" }}>
                    <h2 style={{color:"#888888"}}><a style={{cursor:"pointer"}} onClick={goBack}><BiArrowBack/></a></h2>
                    <h2 style={{marginLeft:"2.6vw"}}>Profile</h2>
                    </div>
                   
                  
                    <div className='container' style={{height: "76vh", width: "92%", overflow:"auto",marginTop:"2vh" }}>
                        <form enctype="multipart/form-data" onSubmit={handleSubmit}>
                       <center><div style={{ border:"1px solid black", marginTop:"6vh",height:"12vh" ,width:"7vw", borderRadius:"100px"}}><img style={{height:"100%",width:"100%",borderRadius:"100px"}} src={image}/>
                           </div></center> 
                       <center>
                       <label htmlFor="image-input" style={{position:"absolute",cursor:"pointer",marginTop:"-5vh",marginLeft:"1.5vw",height:"5.2vh",width:"3vw", backgroundImage: `url(${cam})`, backgroundSize: 'cover', backgroundPosition: 'center' }}>
                       <input type="file" id="image-input" accept="image/*" onChange={handleimage} style={{ display: "none" }}/>
                        </label>
                       </center>
                      <div style={{display:"flex" , justifyContent:"space-evenly" ,paddingLeft:"10vw",marginRight:"10vw", marginTop:"3vh"}}>
                          <div>
                              <h3>Username</h3>
                              <input type="text" value={username} onChange={handleusername} placeholder='   Jacob schamberger' style={{width:"24vw"  , borderRadius:"52px" , border:"none" , boxShadow:"0px 4px 20px rgba(0, 0, 0, 0.12)" ,marginTop:"12px"}}/>
                          </div>
                          <div style={{paddingLeft:"7vw"}}>
                              <h3>Phone number</h3>
                              <input type="text" value={number} onChange={handlenumber} placeholder='   210-853-1120' style={{width:"26vw" , height:"3vh"}}/>      
                          </div>
                      </div>
                      <div style={{display:"flex" , justifyContent:"space-evenly" ,paddingLeft:"10vw",marginRight:"10vw", marginTop:"3vh"}}>
                          <div>
                              <h3>Email id</h3>
                              <input type="text"  value={email} onChange={handleemail} placeholder='   Nico_Bergnaum81@gmail.com' style={{width:"26vw",height:"3vh"}}/>
                          </div>
                          <div style={{paddingLeft:"7vw"}}>
                              <h3>Password</h3>
                              <input type="text" value={password} onChange={handlepassword} placeholder='   Nico_Bergnaum81' style={{width:"26vw" , height:"3vh"}}/>      
                          </div>
                             
                      </div>
                      
                      <div style={{display:"flex" , justifyContent:"flex-end"}}>
                          <button type="submit" style={{height:"4vh",cursor:"pointer",width:"6vw",marginTop:"12vh", color:"white", 
                        background:"linear-gradient( to right, #7A112F 0%,#C92564 100%)",borderRadius:"7px",
                        marginRight:"8vw"}}>
                               Save
                            </button>
                      </div>
                      </form> 
                    </div>
                </div>
             
            
            </div>
            <div>
            
            </div>
        </div>

         )}

export default Editprofile;