import { useState , useEffect } from 'react';
import axios from 'axios';
import Navbar from '../Navbar/Navbar';
import Sidebar from '../Sidebar/Sidebar';



const Notifications=()=>{
    const id = JSON.parse(localStorage.getItem("id"))
    const [users , setusers] = useState([])

    useEffect(()=>{
        ( async()=>{
            const res = await axios.get(`http://192.168.29.1:8088/api/admin/notifications/${id}`)
       
            setusers(res.data)
            console.log(res.data)
         
        })()
        },[]);
   
    return(
        <div className='home'>
            <div>
                <Navbar/>
            </div>
            <div className='mainh'>
                <div><Sidebar/></div>
                <div className='tas' style={{width:"100%"}}> 
                <div style={{display:"flex"}} >
 
                <h2 style={{marginLeft:"2vw"}}>Notifications</h2>
               
                </div>
                   
                  
                    <div className='container' style={{height: "76vh", width: "95%",
                          overflow:"auto",marginTop:"2vh" }}>
                    {users.map((item , i)=>{
                        return(
                       <div key={i} style={{boxShadow: "0px 7px 31px rgba(0, 0, 0, 0.15)",display:"flex" ,height:"10vh" ,width:"90%",marginTop:"3.5vh",marginLeft:"5%"  }}>
                       {/*  <div style={{height:"90%" , width:"6vw"}}> <img style={{height:"90%" ,marginTop:"1vh",marginLeft:"1vw"}} src={item.photo}/></div> */}
                          <div style={{marginLeft:"2vw" , marginTop:"-1vh"}}>
                              <div style={{marginBottom:"-2vh"}}><h4>{item?.notification?.title}</h4></div>
                              <div><p style={{fontSize:"12px"}}>{item?.notification?.detail}</p></div>
                          </div>

                       </div>

                    )})}


                    </div>
                </div>
             
            
            </div>
            <div>
            
            </div>
        </div>

         )}

export default Notifications;