import { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import axios from "axios";
import { baseURL } from "../../api.js";
import Navbar from "../Navbar/Navbar";
import Sidebar from "../Sidebar/Sidebar";
import { BiArrowBack } from "react-icons/bi";
import { useNavigate } from "react-router-dom";
import { BiDotsVerticalRounded } from "react-icons/bi";
import { MdDelete } from "react-icons/md";

const Viewblog = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [blog, setblog] = useState({});
  const [open, setopen] = useState(false);
  useEffect(() => {
    (async () => {
      const res = await axios.get(`${baseURL}/blogs/${id}`);

      setblog(res.data);
    })();
    return () => {};
  }, []);
  const handledelete = async (id) => {
    await axios.delete(`${baseURL}/blogs/${id}`);
    alert("blog deleted successfully");
    navigate("/blogs");
  };
  const goBack = () => {
    navigate("/blogs");
  };

  return (
    <div className="home">
      <div>
        <Navbar />
      </div>
      <div className="mainh">
        <div>
          <Sidebar />
        </div>
        <div className="tas" style={{ width: "100%" }}>
          <div style={{ display: "flex" }}>
            <h2 style={{ color: "#888888", marginLeft: "2vw" }}>
              <a onClick={goBack} style={{ cursor: "pointer" }}>
                <BiArrowBack />
              </a>
            </h2>
            <h2 style={{ marginLeft: "1vw" }}>{blog.heading}</h2>
          </div>

          <div
            className="container"
            style={{
              position: "relative",
              height: "76vh",
              width: "95%",
              overflow: "auto",
              marginTop: "2vh",
            }}
          >
            <BiDotsVerticalRounded
              onClick={() => setopen(!open)}
              style={{
                fontSize: "25px",
                cursor: "pointer",
                position: "absolute",
                marginLeft: "98%",
              }}
            />
            <div
              onClick={() => handledelete(id)}
              style={{
                boxShadow: "0px 4px 20px rgba(0, 0, 0, 0.15)",
                cursor: "pointer",
                marginLeft: "90%",
                display: open ? "flex" : "none",
                padding: "4px",
                height: "2vh",
                width: "6vw",
              }}
            >
              <MdDelete style={{}} />
              <h4 style={{ marginLeft: "1vw", marginTop: "-0.4vh" }}>Delete</h4>
            </div>
            <div
              style={{
                height: "40vh",
                width: "40vw",
                marginTop: "6vh",
                marginLeft: "18vw",
              }}
            >
              <img
                src={blog?.image?.url}
                style={{ height: "100%", width: "100%" }}
              />
            </div>
            <div style={{ marginLeft: "8vw", marginRight: "8vw" }}>
              <p>{blog.description}</p>
            </div>
          </div>
        </div>
      </div>
      <div></div>
    </div>
  );
};

export default Viewblog;
