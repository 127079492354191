import Navbar from "../Navbar/Navbar";
import Sidebar from "../Sidebar/Sidebar";
import { useState, useEffect } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { baseURL } from "../../api.js";
import FormLoader from "../FormLoader.jsx";
const Blog = () => {
  const navigate = useNavigate();
  const handleClick = () => {
    navigate("/addblogs");
  };

  const [users, setusers] = useState([]);
  useEffect(() => {
    (async () => {
      const res = await axios.get(`${baseURL}/blogs/`);
      setusers(res.data);
    })();
    return () => {};
  }, []);
  return (
    <div className="home">
      <div>
        <Navbar />
      </div>
      <div className="mainh">
        <div>
          <Sidebar />
        </div>
        <div className="tas" style={{ width: "100%" }}>
          <div
            className="tasn"
            style={{
              marginLeft: "2vw",
              width: "88%",
              justifyContent: "flex-end",
            }}
          >
            <button
              className="border-none outline-none"
              onClick={handleClick}
              style={{
                cursor: "pointer",
                height: "4vh",
                width: "8vw",
                marginTop: "1vh",
                color: "white",
                background:
                  "linear-gradient( to right, #7A112F 0%,#C92564 100%)",
                borderRadius: "5px",
              }}
            >
              Add Blog
            </button>
          </div>
          <div style={{ display: "flex", marginLeft: "2vw", width: "95%" }}>
            <h2>Blogs</h2>
            <h2 className="blogt"> Total Blogs : {users.length}</h2>
          </div>

          {users.length >= 0 ? (
            <div
              className="container"
              style={{
                display: "flex",
                flexWrap: "wrap",
                height: "70vh",
                width: "90%",
                overflow: "auto",
                marginTop: "2vh",
                paddingLeft: "2vw",
                paddingRight: "2vw",
                paddingBottom: "1vh",
              }}
            >
              {users &&
                users.map((item, i) => {
                  return (
                    <div
                      key={i}
                      onClick={() => navigate(`/viewblog/${item._id}`)}
                      style={{
                        cursor: "pointer",
                        height: "40vh",
                        width: "21vw",
                        border: "1px solid #888888",
                        borderRadius: "10px",
                        marginLeft: "2vw",
                        marginTop: "3vh",
                        marginBottom: "3vh",
                      }}
                    >
                      <div
                        style={{
                          height: "55%",
                          width: "100%",
                          borderBottom: "1px solid #888888",
                        }}
                      >
                        <img
                          style={{
                            width: "100%",
                            height: "100%",
                            borderTopLeftRadius: "10px",
                            borderTopRightRadius: "10px",
                          }}
                          src={item?.image.url}
                        />
                      </div>
                      <div>
                        <h4
                          style={{
                            textAlign: "start",
                            marginLeft: "0.7vw",
                            color: "#777777",
                            marginTop: "1vh",
                            marginBottom: "2vh",
                          }}
                        >{`${item.writers_name} , ${item.date}`}</h4>
                        <h4
                          style={{
                            textAlign: "start",
                            marginLeft: "0.7vw",
                            fontWeight: "700",
                            fontSize: "13px",
                            marginTop: "-1vh",
                            marginBottom: "1vh",
                            paddingTop: "0vh",
                          }}
                        >
                          {item.heading}
                        </h4>
                        <p
                          style={{
                            fontSize: "11px",
                            paddingRight: "1vw",
                            paddingLeft: "0.7vw",
                          }}
                        >
                          {item.description.substring(0, 180)}
                        </p>
                      </div>
                    </div>
                  );
                })}
            </div>
          ) : (
            <FormLoader open={true} />
          )}
        </div>
      </div>
      <div></div>
    </div>
  );
};

export default Blog;
