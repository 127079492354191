import { useEffect } from "react";
import axios from "axios";
import { baseURL } from "../../api.js";
import Navbar from "../Navbar/Navbar";
import Sidebar from "../Sidebar/Sidebar";
import { useNavigate } from "react-router-dom";
import {
  BiDotsVerticalRounded,
  BiLeftArrowAlt,
  BiRightArrowAlt,
} from "react-icons/bi";
import { useState } from "react";
import { Link } from "react-router-dom";
import { MdModeEditOutline, MdDelete } from "react-icons/md";
import { IoMdAddCircle } from "react-icons/io";
import FormLoader from "../FormLoader.jsx";

const Coins = () => {
  const [index, setIndex] = useState(null);

  const navigate = useNavigate();
  const handleClick = () => {
    navigate("/offers");
  };
  const toPlans = () => {
    navigate("/plans");
  };

  const deleteplan = async (id) => {
    await axios.delete(`${baseURL}/coins/${id}`);
    alert("plan deleted");
  };
  const [users, setusers] = useState([]);
  function handleMenuIconClick(id) {
    if (index == id) {
      setIndex(null);
    } else {
      setIndex(id);
    }
    // setIsOpen(!isOpen);
  }
  useEffect(() => {
    (async () => {
      const res = await axios.get(`${baseURL}/coins/`);

      setusers(res.data);
    })();
    return () => {};
  }, [users]);

  return (
    <div className="home">
      <div>
        <Navbar />
      </div>
      <div className="mainh">
        <div>
          <Sidebar />
        </div>
        <div className="tas" style={{ width: "100%" }}>
          <div
            style={{
              display: "flex",
              marginLeft: "2vw",
              height: "6vh",
              width: "94%",
            }}
          >
            <h2>All Plans</h2>
            <h2 className="coint" style={{}}>
              Total Plans: {users.length}
            </h2>
          </div>
          <div
            style={{
              justifyContent: "flex-start",
              display: "flex",
              width: "100%",
            }}
          >
            <button
              onClick={handleClick}
              style={{
                cursor: "pointer",
                height: "4vh",
                width: "8vw",
                marginTop: "3vh",
                marginLeft: "2vw",
                color: "white",
                border: "none",
                outline: "none",
                background:
                  "linear-gradient( to right, #7A112F 0%,#C92564 100%)",
                borderRadius: "5px",
              }}
            >
              Create Offers
            </button>
            <h2>
              <a style={{ cursor: "pointer", paddingLeft: "63vw" }}>
                <BiLeftArrowAlt />
              </a>
            </h2>
            <h2>
              <a style={{ cursor: "pointer", paddingLeft: "2vw" }}>
                <BiRightArrowAlt />
              </a>
            </h2>
          </div>

          {users.length > 0 ? (
            <div
              className="container"
              style={{
                height: "70vh",
                width: "95%",
                overflow: "auto",
                marginTop: "0vh",
              }}
            >
              <table
                className="tab"
                style={{ width: "100%", overflow: "scroll" }}
              >
                <tr className="tabtr">
                  <th style={{ textAlign: "start", paddingLeft: "2vw" }}>
                    Coins
                  </th>
                  <th style={{ paddingLeft: "4vw" }}>Plan</th>
                  <th style={{ textAlign: "end", paddingRight: "2vw" }}>
                    Operation
                  </th>
                </tr>
                {users &&
                  users.map((item, i) => (
                    <tr key={i} style={{ position: "relative" }}>
                      <td
                        style={{
                          padding: "10px 10px 22px 10px",
                          "font-size": "15px",
                          textAlign: "start",
                          paddingLeft: "2vw",
                        }}
                      >
                        {item.coins}
                      </td>
                      <td
                        style={{
                          padding: "10px 10px 10px 60px",
                          "font-size": "15px",
                          textAlign: "center",
                        }}
                      >
                        {item.plan}
                      </td>
                      <td
                        style={{
                          padding: "10px 10px 10px 10px",
                          "font-size": "15px",
                          textAlign: "end",
                          paddingRight: "4vw",
                        }}
                      >
                        <a
                          onClick={() => handleMenuIconClick(item._id)}
                          style={{ cursor: "pointer" }}
                        >
                          <BiDotsVerticalRounded />
                        </a>
                      </td>
                      <div
                        style={{
                          boxShadow: "0px 4px 20px rgba(0, 0, 0, 0.15)",
                          background: "#FFFFFF",
                          borderRadius: "10px",
                          display: index === item._id ? "block" : "none",
                          position: "absolute",
                          right: "68px",
                          top: "-20px",
                        }}
                      >
                        <>
                          {/* <div
                            onClick={() => navigate(`/editcoins/${item._id}`)}
                            style={{
                              cursor: "pointer",
                              display: "flex",
                              padding: "4px",
                              borderBottom: "1px solid black",
                            }}
                          >
                            <MdModeEditOutline
                              style={{ paddingRight: "2vw" }}
                            />
                            <h5 style={{ margin: "0" }}>Edit</h5>
                          </div> */}
                          <div
                            onClick={() => deleteplan(item._id)}
                            style={{
                              cursor: "pointer",
                              display: "flex",
                              padding: "4px",
                              borderBottom: "1px solid black",
                            }}
                          >
                            <MdDelete style={{ paddingRight: "2vw" }} />
                            <h5 style={{ margin: "0" }}>Delete</h5>{" "}
                          </div>
                          {/* <div
                            onClick={toPlans}
                            style={{
                              cursor: "pointer",
                              display: "flex",
                              padding: "4px",
                            }}
                          >
                            <IoMdAddCircle style={{ paddingRight: "2vw" }} />
                            <h5 style={{ margin: "0" }}>Add</h5>
                          </div> */}
                        </>
                      </div>
                    </tr>
                  ))}
              </table>
            </div>
          ) : (
            <FormLoader open={true} />
          )}
        </div>
      </div>
      <div></div>
    </div>
  );
};

export default Coins;
