import React from "react";
import { Route, Routes } from "react-router-dom";

import Home from './components/Home/Home'
import Request from "./components/Request/Request";
import Coins from "./components/Coins/Coins";
import Blog from "./components/Blog/Blog";
import Payment from "./components/Payment/Payment";
import Categories from "./components/Categories/Categories";
import Questions from "./components/Questions/Questions";
import Offers from "./components/Coins/Offers";
import Plans from "./components/Coins/Plans";
import Editcoins from "./components/Coins/Editcoins";
import Addcategories from "./components/Categories/Addcategory";
import Addques from "./components/Questions/Addques";
import Form from "./components/Categories/Form";
import Addblogs from "./components/Blog/Addblogs";
import Login from "./components/Login/Login";
import Signup from "./components/Signup/Signup";
import Forgotpassword from "./components/Forgotpassword/Forgotpassword";
import Changepassword from "./components/Changepassword/Changepassword";
import Editusers from "./components/Editusers/Editusers";
import Reportcoins from "./components/Coins/Report";
import Query from "./components/Query/Query";
import Viewblog from "./components/Blog/Viewblog";
import Editprofile from "./components/Editprofile/Editprofile";
import Notifications from "./components/Notifications/Notification";


const RoutesList =()=>{
    return(

            <Routes>

            <Route exact path="/" element={<Login/>} />
            <Route exact path="/home" element={<Home/>}/>
            <Route exact path="/signup" element={<Signup/>}/>
            <Route exact path="/requests" element={<Request/>}/>
            <Route exact path="/coins" element={<Coins/>}/>
            <Route exact path="/blogs" element={<Blog/>}/>
            <Route exact path="/payments" element={<Payment/>}/>
            <Route exact path="/categories" element={<Categories/>}/>
            <Route exact path="/questions" element={<Questions/>}/>
            <Route exact path="/offers" element={<Offers/>}/>
            <Route exact path="/plans" element={<Plans/>}/>
            <Route exact path="/editcoins/:id" element={<Editcoins/>}/>
            <Route exact path="/addcat" element={<Addcategories/>}/>
            <Route exact path="/addques" element={<Addques/>}/>
            <Route exact path="/form" element={<Form/>}/>
            <Route exact path="/addblogs" element={<Addblogs/>}/>
            <Route exact path="/forgot" element={<Forgotpassword/>}/>
            <Route exact path="/change" element={<Changepassword/>}/>
            <Route exact path="/editusers/:id" element={<Editusers/>}/>
            <Route exact path="/report" element={<Reportcoins/>}/>
            <Route exact path="/query" element={<Query/>}/>
            <Route exact path="/viewblog/:id" element={<Viewblog/>}/>
            <Route exact path="/editprofile" element={<Editprofile/>}/>
            <Route exact path="/notifications" element={<Notifications/>}/>
            
            </Routes>
   

          )}


export default RoutesList;