import logo from "./logo.png";
import { AiOutlineRight } from "react-icons/ai";
import { Form, Link, useNavigate } from "react-router-dom";
import { BsEyeSlash } from "react-icons/bs";
import { useState } from "react";
import axios from "axios";
import { baseURL } from "../../api";
import FormLoader from "../FormLoader";
import { useForm } from "react-hook-form";

const Signup = () => {
  const {
    handleSubmit,
    formState: { isSubmitting },
  } = useForm();
  const navigate = useNavigate();
  const [isPasswordVisible, setIsPasswordVisible] = useState(false);
  const [number, setnumber] = useState("");
  const [password, setpassword] = useState("");
  const [username, setusername] = useState("");

  const togglePasswordVisibility = () => {
    setIsPasswordVisible((prevState) => !prevState);
  };

  const handleNumber = (e) => [setnumber(e.target.value)];
  const handlePassword = (e) => {
    setpassword(e.target.value);
  };
  const handleUsername = (e) => {
    setusername(e.target.value);
  };
  const submitForm = async () => {
    const res = await axios.post(
      `${baseURL}/admin/`,
      { number, password, username },
      {
        headers: { "Content-Type": "application/json" },
      }
    );

    if (res.data) {
      localStorage.setItem("token", JSON.stringify(res.data.token));
      navigate("/");
    } else {
      alert("Your credentials are wrong");
    }
  };

  return (
    <div style={{ height: "100vh", width: "100vw", display: "flex" }}>
      <div
        style={{
          width: "40vw",
          height: "100vh",
          background: "linear-gradient(90deg, #7A112F 0%, #C92564 100%)",
          boxShadow: "13px 0px 92px rgba(0, 0, 0, 0.18)",
        }}
      >
        <img className="logo" src={logo} />
        <div style={{ width: "16vw", marginTop: "20vh", marginLeft: "6vw" }}>
          <h1 style={{ color: "white", fontSize: "60px", marginBottom: "1vh" }}>
            Sign up
          </h1>
          <hr
            style={{
              width: "6.5vw",
              transform: "rotate(1.79deg)",
              height: "0.5vh",
              background: "#FFFFFF",
              marginLeft: "1vw",
            }}
          />
        </div>
      </div>
      <div
        style={{
          height: "7vh",
          marginLeft: "38vw",
          marginTop: "46.5vh",
          position: "absolute",
          width: "4vw",
          borderRadius: "30px",
          background: "linear-gradient(0deg, #C92564, #C92564)",
        }}
      >
        <AiOutlineRight
          style={{
            color: "#FFFFFF",
            marginTop: "1.8vh",
            marginLeft: "1.8vw",
            fontSize: "25px",
          }}
        />
      </div>
      <form onSubmit={handleSubmit(submitForm)}>
        <div style={{ marginTop: "25vh", width: "32vw", marginLeft: "15vw" }}>
          <div>
            <h3
              style={{
                color: "#7A112F",
                fontWeight: "500",
                marginBottom: "1vh",
              }}
            >
              Username
            </h3>
            <input
              type="text"
              value={username}
              onChange={handleUsername}
              placeholder="Enter your name here"
              style={{
                paddingBottom: "0.8vh",
                width: "30vw",
                outline: "0",
                borderWidth: "0 0 2px",
                borderColor: "#7A112F",
              }}
            />
          </div>
          <div style={{ marginTop: "4vh" }}>
            <h3
              style={{
                color: "#7A112F",
                fontWeight: "500",
                marginBottom: "1vh",
              }}
            >
              Phone Number
            </h3>
            <input
              type="text"
              value={number}
              onChange={handleNumber}
              placeholder="Enter your phone number"
              style={{
                paddingBottom: "0.8vh",
                width: "30vw",
                outline: "0",
                borderWidth: "0 0 2px",
                borderColor: "#7A112F",
              }}
            />
          </div>
          <div style={{ position: "relative", marginTop: "4vh" }}>
            <h3
              style={{
                color: "#7A112F",
                fontWeight: "500",
                marginBottom: "1vh",
              }}
            >
              Password
            </h3>
            <input
              type={isPasswordVisible ? "text" : "password"}
              value={password}
              onChange={handlePassword}
              placeholder="Enter password"
              style={{
                paddingBottom: "0.8vh",
                width: "30vw",
                outline: "0",
                borderWidth: "0 0 2px",
                borderColor: "#7A112F",
              }}
            />
            <BsEyeSlash
              onClick={togglePasswordVisibility}
              style={{
                position: "absolute",
                marginTop: "0.5vh",
                marginLeft: "-2vw",
              }}
            />
          </div>
        </div>
        <div>
          <button
            style={{
              height: "5vh",
              border: "0",
              color: "#FFFFFF",
              marginTop: "10vh",
              marginLeft: "27vw",
              width: "6vw",
              background: "linear-gradient(90deg, #7A112F 0%, #C92564 100%)",
              borderRadius: "7px",
            }}
          >
            Sign up
          </button>
          {isSubmitting && <FormLoader open={true} />}
        </div>
        <div style={{ marginLeft: "22vw" }}>
          <h4 style={{ color: "#7A112F", fontWeight: "600" }}>
            Already have an account?{" "}
            <Link to="/" style={{ color: "#7A112F" }}>
              Login
            </Link>
          </h4>
        </div>
      </form>
    </div>
  );
};
export default Signup;
