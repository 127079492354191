import Navbar from '../Navbar/Navbar';
import Sidebar from '../Sidebar/Sidebar';
import { useNavigate } from 'react-router-dom';
import { BiArrowBack} from "react-icons/bi";
import { useState } from 'react';
const Addques=()=>{
    const navigate = useNavigate();
 
    
    const goBack=()=>{
        navigate("/questions")
    }
    const [selectedBoxes, setSelectedBoxes] = useState([]);
    const boxes = [
        { id: 0, color: 'pink' , type:"Yes" },
        { id: 1, color: 'pink' ,type:"No"},
        { id: 2, color: 'pink' , type:"Sometimes" },
      ];
      function handleBoxClick(boxId) {
        if (selectedBoxes.includes(boxId)) {
            // If the box is already selected, remove it from the list
            setSelectedBoxes(selectedBoxes.filter((id) => id !== boxId));
          } else {
            // If the box is not selected, add it to the list
            setSelectedBoxes([...selectedBoxes, boxId]);
          }
      }
    return(
        <div className='home'>
        <div>
            <Navbar/>
        </div>
        <div className='mainh'>
            <div><Sidebar/></div>
            <div className='tas' style={{width:"100%"}}> 
            <div style={{display:"flex"}} >
                <h2 style={{color:"#888888",marginTop:"",marginLeft:"2vw" }}><a onClick={goBack}  style={{cursor:"pointer"}} ><BiArrowBack/></a></h2>
                <h2 style={{marginLeft:"1vw"}}>Create Form</h2>
               
                </div>
              
                <div className='container' style={{height: "75vh", width: "90%", paddingTop:"2vh",overflow:"auto",marginTop:"2vh",paddingLeft:"2vw",paddingRight:"2vw" }}>
               <h2 >Enter Question that you want to ask</h2>
               <h3>Question</h3>
               <input  style={{width:"30vw",height:"4vh", border:"1px solid #DADADA",borderRadius: "5px"}} type="text" placeholder="  Enter question which you want to ask .."/>
               <h3>Select Answer types</h3>
               <div style={{display:"flex" ,alignItems:"start",flexDirection:"column",height:"16vh" , width:"17vw" , border:"1px solid #DADADA" ,borderRadius: "5px",paddingTop:"0.5vh"}}>
                   {boxes.map((box , i)=>(
                       <>
                        <div key={i}
                         
                            onClick={() => handleBoxClick(box.id)}
                       
                            style={{border: selectedBoxes.includes(box.id) ? "1px solid #7A112F":"1px solid #DADADA", color: selectedBoxes.includes(box.id) ? "#7A112F":"black",cursor:"pointer",fontSize:"12px", padding:"5px",maxWidth:"8vw",borderRadius:"30px",textAlign:"center",margin:"0.7vh 0.7vw" }}>{box.type}
                        </div>
                    
                        </>
                         ))}
               
               </div>
               <div style={{display:"flex" , justifyContent:"flex-end"}}>
                          <button style={{ cursor:"pointer",height:"4vh",width:"8vw",marginTop:"16vh", color:"white", 
                        background:"linear-gradient( to right, #7A112F 0%,#C92564 100%)",borderRadius:"5px",
                        marginRight:"8vw"}}>
                            Add Question
                            </button>
                      </div>
                </div>
            </div>
         
        
        </div>
        <div>
        
        </div>
    </div>


         )}

export default Addques;
