import Navbar from '../Navbar/Navbar';
import Sidebar from '../Sidebar/Sidebar';
import { useNavigate } from 'react-router-dom';

const Questions=()=>{
    const navigate = useNavigate();
    const data= [
        {ques: "1. Ac aenean diam malesuada et volutpat vitae enim ac. Praesent quisque cum diam sodales rhoncus amet dignissim. Nibh commodo commodo sed luctus vel viverra?"},
        {ques: "2. Ornare pulvinar nibh ipsum vivamus. Sed dignissim ante varius risus nulla quis viverra arcu. Tellus vulputate metus nunc arcu eu sit neque?"},
        {ques: "3. Ac iaculis semper nisl maecenas eros et ac neque. Dolor aliquet elementum egestas et volutpat. Ipsum egestas ut aenean at venenatis ut egestas?"},
        {ques: "4. Faucibus tristique ut consectetur commodo. Quam blandit est in dictum consequat tortor non. Posuere dignissim viverra sed commodo scelerisque egestas dictumst et vestibulum?"},
        {ques: "5. Fames porta hendrerit risus vel. Consequat leo interdum in praesent volutpat. Elementum mattis praesent sapien fames?"},
        {ques: "6. Tristique amet lorem sed dolor hac lacus. Accumsan at nunc ornare vel nec pellentesque ac magna. In dolor sem ullamcorper in ut adipiscing ultricies vel?"},
        {ques: "7. Bibendum lacinia nunc ultrices massa orci placerat. Mauris vitae malesuada mi auctor enim odio. In tellus tellus vitae quisque?"},
        {ques: "8. Faucibus nibh lobortis odio turpis magna est feugiat. Sit pretium scelerisque bibendum mauris amet. At lacus sed nisl interdum mattis?"},
        {ques: "9. Eget volutpat pulvinar parturient tellus sed tincidunt vitae diam. Amet ultricies in purus elit erat dictum donec non. Quam odio cras nulla cum lorem at?"},
        {ques: "10. Bibendum scelerisque imperdiet tempus eros lorem ultrices quam. Cursus nam ut varius porta aliquam turpis. Sit leo nulla natoque ornare eget lectus urna consequat at?"},
    ]
    const handleClick=()=>{
             navigate("/addques")
    }
    return(
        <div className='home'>
        <div>
            <Navbar/>
        </div>
        <div className='mainh'>
            <div><Sidebar/></div>
            <div className='tas' style={{width:"100%"}}> 
                
                <div className='tasn' style={{marginLeft:"1.6vw",width:"90%" }}>
                    <button onClick={handleClick} style={{marginLeft:"58vw", cursor:"pointer",height:"4vh",width:"12vw",marginTop:"1vh", color:"white", 
                    background:"linear-gradient( to right, #7A112F 0%,#C92564 100%)",borderRadius:"5px"}}>Add More Questions</button>
       
                </div>
                <div  style={{display:"flex",marginLeft:"2vw",width:"95%"}}>
                <h2>Questions</h2>
                <h2 className='quest' >Total Questions:{data.length}</h2>
                </div>
              
                <div className='container' style={{height: "70vh", width: "90%", overflow:"auto",marginTop:"2vh",paddingLeft:"2vw",paddingRight:"0vw" }}>
                {data && data.map((item,i)=>
                (
                   <h4 style={{color:"#000000" , fontSize:"13px" , fontWeight:"400"}}>{item.ques}</h4>

                )) }
                </div>
            </div>
         
        
        </div>
        <div>
        
        </div>
    </div>


         )}

export default Questions;