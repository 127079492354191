import logo from './logo.png'
import { AiOutlineRight } from "react-icons/ai";
import { Link } from 'react-router-dom';
import { BsEyeSlash } from "react-icons/bs";


const Changepassword=()=>{
    return(
        <div style={{height:"100vh" ,display:"flex", width:"100vw"}}>
             <div style={{width:"40vw" , height:"100vh" , background:"linear-gradient(90deg, #7A112F 0%, #C92564 100%)",boxShadow: "13px 0px 92px rgba(0, 0, 0, 0.18)"}}>
                
                <img className='logo' src={logo}/>
                <div style={{width:"33vw", marginTop:"20vh", marginLeft:"6vw"}}>
                <h1 style={{color:"white", fontSize:"54px",marginBottom:"1vh",}}>Change Password</h1>
                <hr style={{width:"13.5vw",transform: "rotate(1.79deg)",height:"0.5vh",background:"#FFFFFF",marginLeft:"1vw"}}/>
                </div>
               
                
               
            </div>
            <div style={{height:"7vh",marginLeft:"38vw" ,marginTop:"46.5vh", position:"absolute",width:"4vw",borderRadius:"30px",
            background:"linear-gradient(0deg, #C92564, #C92564)"}}>
                    <AiOutlineRight style={{color:"#FFFFFF",marginTop:"1.8vh",marginLeft:"1.8vw" , fontSize:"25px"}}/>
                    </div>
            <div>
            <div style={{marginTop:"33vh",width:"32vw",marginLeft:"15vw"}}>
   
                        <div style={{position:"relative",marginTop:"4vh"}}>
                            <h3 style={{color:"#7A112F",fontWeight:"500",marginBottom:"1vh"}}> New Password</h3>
                        <input type="text"  placeholder='Enter password' style={{marginBottom:"-2vh",paddingBottom:"0.8vh",width:"30vw",outline:"0",borderWidth:"0 0 2px",borderColor:"#7A112F"}} />
                        <BsEyeSlash style={{position:"absolute",marginTop:"0.5vh" ,marginLeft:"-2vw"}}/>
                        </div>
                        <div style={{position:"relative",marginTop:"4vh"}}>
                            <h3 style={{color:"#7A112F",fontWeight:"500",marginBottom:"1vh"}}>Confirm Password</h3>
                        <input type="text"  placeholder='Enter password' style={{marginBottom:"-2vh",paddingBottom:"0.8vh",width:"30vw",outline:"0",borderWidth:"0 0 2px",borderColor:"#7A112F"}} />
                        <BsEyeSlash style={{position:"absolute",marginTop:"0.5vh" ,marginLeft:"-2vw"}}/>
                        </div>
                    </div>
                    <div>
                        <button style={{height:"5vh",cursor:"pointer" , border:"0",color:"#FFFFFF",marginTop:"10vh",marginLeft:"24vw",width:"12vw",background: "linear-gradient(90deg, #7A112F 0%, #C92564 100%)",borderRadius:"7px"}}>Change Password</button>
                    </div>     
            </div>
        </div>
    )
}

export default Changepassword;