import "./Navbar.css"
import logo from './logo.png'
import search from './search.png'
import notify from './notify.png'
import { useNavigate } from "react-router-dom"
import { useState , useEffect } from "react"
import axios from "axios"



const Navbar=()=>{
    const navigate = useNavigate();
    const goHome=()=>{
          navigate("/dash")
    }
    const prof =()=>{
        navigate("/editprofile")
    }
    // const id = JSON.parse(localStorage.getItem("id"))
    const [dataa,setdataa] = useState({})
    const [image,setimage] = useState('')
    const [username , setusername] = useState('')
   
    // const fetchData= async()=>{
    //    const {data} = await axios.get(`http://192.168.1.16:8088/api/admin/${id}`)
    //    setdataa(data)
    //    const initialimage = data?.profilePic.url || '' ;
    //    const initialusername = data?.username || '';

    //    setusername(initialusername);
    //    setimage(initialimage)  
    // }
    
    // useEffect(()=>{
    //    fetchData() 
    // },[])
return(

    <div className="nav">
       <a onClick={goHome} style={{cursor:"pointer"}}><img className="logo" src={logo} /></a>
      
       <div className="plus" >
           <img className="srcicon" src={search}/>
           <input className="srch" type="text" placeholder="Search here"/>
       </div>
       
       <div style={{display:"flex" , marginLeft:"28vw"}}>
           {/* <a onClick={prof}><img style={{cursor:"pointer", object:"cover",height:"7vh",marginRight:"2vw",marginTop:"1.5vh"}} className="navimg" src={image} alt="profile" /></a> */}
           <div className="nav31">
               <h3 className="firts" >{username}</h3>
              <h3 className="secnods"  >Admin of Association</h3>
           </div>
               
         
       </div>
    </div>
)

}

export default Navbar;