import Navbar from '../Navbar/Navbar';
import { useState } from 'react';
import axios from 'axios';
import Sidebar from '../Sidebar/Sidebar';
import { BiArrowBack} from "react-icons/bi";
import { useNavigate } from 'react-router-dom';

const Plans=()=>{
    const navigate = useNavigate();
    const goBack=()=>{
        navigate("/coins")
    }
    const [plan , setplan] = useState("")
    const [coins,setcoins] = useState(0)
    const handleplan =(e)=>{
        setplan(e.target.value)
    }
    const handlecoins=(e)=>{
        setcoins(e.target.value)
    }
    const addPlan= async()=>{
        await axios.post("http://192.168.29.1:8088/api/coins/" , {plan:plan,coins:coins },
        {
           headers: { 'Content-Type': 'application/json' }
         })
         alert("Plan Added!")
    }
    return(
        <div className='home'>
            <div>
                <Navbar/>
            </div>
            <div className='mainh'>
                <div><Sidebar/></div>
                <div className='tas' style={{width:"100%"}}> 
                    <div  style={{display:"flex",marginLeft:"2vw",width:"80%" }}>
                    <h2 style={{color:"#888888"}}><a onClick={goBack} style={{cursor:"pointer"}}><BiArrowBack/></a></h2>
                    <h2 style={{marginLeft:"3vw"}}>Add Plans</h2>
                    </div>
                   
                  
                    <div className='container' style={{height: "76vh", width: "95%", overflow:"auto",marginTop:"2vh" }}>
                      <div style={{display:"flex" , marginLeft:"9.6vw",marginTop:"8vh"}}>
                          <div style={{marginRight:"4vw"}}>
                              <h3>Plan</h3>
                              <input type="text" value={plan} onChange={handleplan} placeholder='  Enter Plan' style={{width:"26vw",height:"3vh"}}/>
                          </div>
                          <div>
                              <h3>Coins</h3>
                              <input type="text" value={coins} onChange={handlecoins} placeholder='  Enter coin' style={{width:"26vw" , height:"3vh"}}/>      
                          </div>
      
                      </div>
                      
                      <div style={{display:"flex" , justifyContent:"flex-end"}}>
                          <button onClick={addPlan} style={{cursor:"pointer",height:"4vh",width:"8vw",marginTop:"40vh", color:"white", 
                        background:"linear-gradient( to right, #7A112F 0%,#C92564 100%)",borderRadius:"5px",
                        marginRight:"11vw"}}>
                            Add Plan
                            </button>
                      </div>
                    </div>
                </div>
             
            
            </div>
            <div>
            
            </div>
        </div>

         )}

export default Plans;